import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import BlogHighlight from "../../components/blog/blogHighlight"
import BlogList from '../../components/blog/blogList'

import blogFeaturedImage from "../../../static/home/wowmakers.jpeg"


import '../../sass/blog/load-more.scss'

export const Head = () => (
  <>
    <SeoHead 
      ogImage={blogFeaturedImage}
      title="WowMakers Blog | Thoughts on User Experience Design, Video Marketing, Branding and more."
      description="Our blog shares thoughts, tips, and lessons on human-centered design, storytelling, branding, video marketing and more."
    />
  </>
)

const Blog = () => {
  const data = useStaticQuery(graphql` 
  {
    allWpPost(sort: {fields: date, order: DESC}, skip: 1) {
      nodes {
        title 
        excerpt
        uri
        slug
        content
        date(formatString: "MMM DD, YYYY")
        relPost {
          externalLink
        }
        categories {
          nodes {
            name
            uri
          }
        }
        author {
          node {
            name
            usersAcf {
              designation
              uploadProfileImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(formats: AUTO, width: 35, height: 35, placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }                
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(formats: AUTO, width: 386, height: 216, placeholder: BLURRED)
              }
            }
          }
        }        
      }
    }
  }    
  `)

  const allBlogs = data.allWpPost.nodes

  const [list, setList] = useState([...allBlogs.slice(0, 12)])

  const [loadMore, setLoadMore] = useState(false)

  const [hasMore, setHasMore] = useState(allBlogs.length > 12)

  const handleLoadMore = () => {
    setLoadMore(true)
  }

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allBlogs.length
      const nextResults = isMore
        ? allBlogs.slice(currentLength, currentLength + 12)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < allBlogs.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line


  return (
    <Layout>
      <Seo bclass="blog" bid="blog-listing"/>
      <ScrollIndicationHeightZero />
      <BlogHighlight />
      
      <Container>
        <Row>
          {list.map((node, index) => (
              <BlogList key={node.id} node={node} index={index}/>
          ))}          
        </Row>
        <Row>
          <Col>
            <div className="load-more-posts text-center">
              {hasMore ? (
              <button onClick={handleLoadMore} class="btn">Show More</button>
            ) : (
              <p>No more Posts</p>
            )}
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )  
}

export default Blog