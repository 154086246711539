import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col } from 'react-bootstrap'
import "../../sass/blog/bloghighlight.scss"

const readingTime = require('reading-time');
const options = { wordsPerMinute: 400 }


export default function BlogHighlight() {
  
  const data = useStaticQuery(graphql`
  {
    allWpPost(sort: {fields: date, order: DESC}, limit: 1) {
      nodes {
          title
          excerpt
          uri
          slug
          date(formatString: "MMM DD, YYYY")
          content
          author {
            node {
              name
              usersAcf {
                designation
                uploadProfileImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(formats: AUTO, width: 60, height: 60, placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          }                
          categories {
              nodes {
                name
                uri
              }
          }                
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: AUTO, width: 591, height: 332, placeholder: BLURRED)
                }
              }
            }
          }
      }
    }
  }
`)
  return (
    <Container>
    <Row>
        {data.allWpPost.nodes.map(node => (
            
        <Col md={12}>
            <div className="blog-header">
                <article className="latest-artcle">
                    <Row>
                        <Col lg={6} className="d-flex align-items-center">
                            <div className="article-f-image">
                                <Link to={node.uri} className="f-thumb-url">
                                    <GatsbyImage image={getImage(node.featuredImage.node.localFile)} alt={ node.featuredImage.node.altText } />
                                </Link>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="header-content">
                                <Link to={node.uri} className="f-title-url">
                                    <h1>{node.title}</h1>
                                </Link>
                                <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                                <div className="header-meta d-flex align-items-center justify-content-between">
                                    <time dateTime={node.date}>{node.date}</time>
                                    <span>
                                        <svg width="24" height="10" viewBox="0 0 24 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="5.7998" cy="5" r="4" stroke="#B8B8B8" stroke-width="1.59999" stroke-linecap="round"></circle>
                                            <path d="M9.65337 2.24106C9.23422 2.10134 8.78117 2.32787 8.64145 2.74702C8.50173 3.16617 8.72826 3.61922 9.14741 3.75894L9.65337 2.24106ZM14.4534 3.75894C14.8725 3.61922 15.099 3.16617 14.9593 2.74702C14.8196 2.32787 14.3666 2.10134 13.9474 2.24106L14.4534 3.75894ZM11.8004 3.8L11.5474 4.55894C11.7116 4.61368 11.8892 4.61368 12.0534 4.55894L11.8004 3.8ZM9.14741 3.75894L11.5474 4.55894L12.0534 3.04106L9.65337 2.24106L9.14741 3.75894ZM12.0534 4.55894L14.4534 3.75894L13.9474 2.24106L11.5474 3.04106L12.0534 4.55894Z" fill="#B8B8B8"></path> <path d="M22.5996 5.79922L22.5996 4.19922" stroke="#B8B8B8" stroke-width="1.59999" stroke-linecap="round"></path>
                                            <path d="M1 5.79922L1 4.19922" stroke="#B8B8B8" stroke-width="1.59999" stroke-linecap="round"></path>
                                            <circle cx="17.7998" cy="5" r="4" stroke="#B8B8B8" stroke-width="1.59999" stroke-linecap="round"></circle> </svg>
                                        <span className="span-reading-time">
                                          {
                                          
                                          readingTime(node.content, options).text
                                          }
                                        </span>
                                    </span>
                                    <Link to={node.categories.nodes[0]['uri']}>{node.categories.nodes[0]['name']}</Link>
                                </div>
                                <div className="user-details">
                                    <div className="avatar">
                                        <GatsbyImage image={getImage(node.author.node.usersAcf.uploadProfileImage.localFile)} alt="blog author avatar" />
                                    </div>
                                    <div class="details">
                                        <strong>{node.author.node.name}</strong>
                                        <span>{node.author.node.usersAcf.designation}</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </article>
            </div>
        </Col>
        ))}
    </Row>
</Container>
  )
}